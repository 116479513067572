import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog18.png"

export default function IsCaffeineBadForUs(){
    return(
        <BlogPostOld
            src={image}
            title={"Is Caffeine Bad For Us"}
            summary={"Is caffeine bad for us? Is there a certain amount of caffeine that is unhealthy? What does caffeine do? I feel like we get bashed nowadays for the degree of which we love our caffeinated beverages. "}
            date={"August 1, 2022"}
            author={"Dr. Donald Mull, DC"}
            InStyle={"max-h-full"}
        >
            <p>Is caffeine bad for us? Is there a certain amount of caffeine that is unhealthy? What does caffeine do? I feel like we get bashed nowadays for the degree of which we love our caffeinated beverages.&nbsp;</p>
            <p>Me personally? I am a huge fan of caffeine and if I had known some of the side effects I would have made it a nutritional staple to drive performance while playing collegiate football. I have heard it all when it comes to opinions and preconceived notions about caffeine.</p>
            <p>Caffeine is a drug, it is bad for you. Regular caffeine use over time isn&rsquo;t good for your heart. So many opinions out there that paint such a negative picture about caffeine. So instead of jumping on the negative nancy bandwagon, I want you to watch this video (these guys are beyond phenomenal and highly recommend following them - they have challenged my bias and helped me grow as a clinician on a regular basis).&nbsp;</p>

            <iframe className={"w-full"} width="560" height="315" src="https://www.youtube.com/embed/7TD0khFEIXo?start=263" title="YouTube
                video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;
                gyroscope; picture-in-picture" allowFullScreen>
        </iframe>
            <p>If you don&rsquo;t have the time to nerd out and learn straight from the source, I get it. Here are some of my favorite takeaways (but seriously, watch that video when you can it is gold).&nbsp;</p>
            <ol>
                <li>1. 85 percent of population consumes at least one serving of caffeine everyday</li>
                <li>2. Caffeine does not increase heart rate (just how hard the heart contracts)</li>
                <li>3. Can increase muscle excitability&nbsp;</li>
                <li>4. 400 mg a day (4-5 cups) per day is generally considered healthy long term WITH NO SIDE EFFECTS</li>
                <li>5. Lethal dose is approximately 150-200mg/kg (75 - 100 cups of coffee for someone weighing 154 lbs&hellip; basically impossible!)</li>
                <li>6. People who drink caffeine regularly don&rsquo;t see a change in blood pressure (may slightly increase those who don&rsquo;t drink it normally)</li>
                <li>7.Moderate to even high intakes of caffeine is not associated with increased risks of any cardiovascular disease (high blood pressure, cardiac arrythmias, etc)&nbsp;</li>
                <li>8. Avoid caffeine 6-8 hours before sleeping (though this is very general - some people tolerate it prior to sleeping just fine).</li>
                <li>9.Caffeine hits you faster when consumed via gum (measured in blood) and slower when consumed via capsules.&nbsp;</li>
                <li>10. The amount of caffeine in the blood is slightly higher when consumed via coffee when compared to energy (big win for coffee here and I will take it)</li>
                <li>11. When taken orally 99% of caffeine is absorbed within 45 minutes</li>
                <li>12. Cognitive and physical performance remains even in those who regularly consume (so no need to worry about needing to detox!)</li>
                <li>13. Performance enhancing dose is 3-9mg/kg bodyweight (generally). So roughly 154 lb individual 2 cups (200mg) is a great dose for performance roughly 60 min before exercise.&nbsp;</li>
            </ol>
            <p>Moral of the story is that caffeine is NOT bad for you. It can increase physical and cognitive performance with virtually ZERO side effects with long term continuous use. I hope you enjoyed this summary of why caffeine is so awesome, I know I did (and that&rsquo;s all that matters).</p>
            </BlogPostOld>

    )
}